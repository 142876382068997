"use client";

import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";

const states = [
  { value: "alabama", label: "Alabama" },
  { value: "new-york", label: "New York" },
  { value: "san-francisco", label: "San Francisco" },
  { value: "los-angeles", label: "Los Angeles" },
] as const;

function FormParent({
  subheader,
  title,
  children,
  prevShowing,
  handleNext,
  handlePrev,
  nextDisabled,
  fillDataCallBack,
  hasCache,
}: any): React.JSX.Element {
  const [forceToggle, setForceToggle] = useState(false);

  useEffect(() => {
    if (forceToggle) setForceToggle(false);
  }, [forceToggle]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        subheader={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "30px" }}>{subheader}</span>
            {!prevShowing && hasCache && (
              <Button
                onClick={() => {
                  fillDataCallBack();
                  setForceToggle(true);
                }}
                sx={{ maxWidth: "290px" }}
              >
                Crear amparo con la informacion anterior
              </Button>
            )}
          </div>
        }
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{title}</span>
          </div>
        }
      />
      <Divider />
      <CardContent>{!forceToggle && children}</CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        {prevShowing && (
          <Button onClick={handlePrev} variant="contained">
            Anterior
          </Button>
        )}
        <Button
          disabled={nextDisabled}
          onClick={handleNext}
          variant="contained"
        >
          Siguiente
        </Button>
      </CardActions>
    </Card>
  );
}

export default FormParent;
