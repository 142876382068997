import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const usePostProtection = () => {
  return useMutation({
    mutationFn: (data: any) =>
      axios.post("https://backend.lexibot.com.mx/protection/createProtection", data),
      // axios.post("http://localhost:8080/protection/createProtection", data),

  });
};

export default usePostProtection;
