import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from "@mui/material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      color: theme.palette.grey[700],
      zIndex: 1,
      fontSize: 18,
    },
  })
);

function QontoStepIcon(props: StepIconProps, IconStepper: any) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <IconStepper className="QontoStepIcon-completedIcon" />
      ) : (
        <IconStepper className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const Wizard = ({ steps, step }: any) => {
  return (
    <Stepper
      sx={{ width: "100%", alignSelf: "center" }}
      alternativeLabel
      activeStep={step}
      connector={<QontoConnector />}
    >
      {steps.map(({ label, icon }: any) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => QontoStepIcon(props, icon)}
          />
        </Step>
      ))}
    </Stepper>
  );
};

export default Wizard;
