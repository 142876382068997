import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import PaymentForm from "../../protection/forms/PaymentForm";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";

import "../../../App.css";

const PRICE = 799.00;
// const stripePromise = loadStripe(
//   "pk_test_51OrXq7IWL9yszq9KU5436iexyjQs3hydDbI5fi6ckPGAGFbtF4RINjvlx2N6Cf1aEdLaz5GbHFJxQ1PtuLb3XlHl009GaQJTm1"
// );
const stripePromise = loadStripe(
  "pk_live_51OrXq7IWL9yszq9K9d2BAzwi3w17JqU3JL5Ro8ft9a2yQfgopofqEqBC8jjJWI0Lr4zO8dBYY8iv5Qcl5naVsF0A00qH5Wh7Pl"
);

export interface ICoupon {
  _id: string;
  name: string;
  description?: string;
  amount: Number;
  percentage?: Number;
  createdAt?: Date;
  updatedAt?: Date;
  validUntil?: Date;
  active: boolean;
}

function CheckoutForm({ formik, values }: any): React.JSX.Element {
  const [couponId, setCouponId] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [fullAmountCoupon, setFullAmountCoupon] = useState<boolean>(false);
  const [couponObj, setCouponObj] = useState<ICoupon | null>(null);
  const [isCouponValid, setIsCouponValid] = useState<boolean | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch("http://localhost:8080/payment/create-payment-intent", {
    fetch("https://backend.lexibot.com.mx/payment/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ }] }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
      });
    formik.setFieldValue("paymentStatus", false);
  }, []);

  useEffect(() => {
    formik.setFieldValue("paymentId", paymentId);
    formik.setFieldValue("couponId", couponId);
    if (paymentStatus) {
      formik.setFieldValue("paymentStatus", paymentStatus);
    }
  }, [paymentStatus]);

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  const amountLabel = () => {
    const couponValue: Number = couponObj == null ? 0 : couponObj?.amount;
    const amount = couponValue != null ? PRICE - couponValue.valueOf() : PRICE;
    return (
      <>
        <span>Cantidad a pagar: $</span>
        <span
          style={{ textDecoration: isCouponValid ? "line-through" : "none" }}
        >
          {PRICE}{" "}
        </span>
        {isCouponValid && couponObj != null && <span>{amount}</span>}
      </>
    );
  };

  const onCouponNameChange = (event: any) => {
    setCouponValue(event.target.value);
    setIsCouponValid(null);
  };

  const onApplyCoupon = async () => {
    try {
      const couponObj = await getIsCouponActive()
      if (couponObj.active) {
        setIsCouponValid(true);
        if (couponObj.amount == PRICE){
          setFullAmountCoupon(true)
          setPaymentStatus(true)
          await formik.setFieldValue("fullAmountCoupon", true);
        }
      } else {
        setIsCouponValid(false);
      }
    } catch (e) {
      setIsCouponValid(false);
    }
  };

  const onDeleteCoupon = async () => {
    setIsCouponValid(null);
    setCouponValue("");
    setCouponObj(null);
    setFullAmountCoupon(false)
  };

  const getIsCouponActive = async () => {
    const couponObj: ICoupon = await axios
      .get<ICoupon>(
        "https://backend.lexibot.com.mx/coupon/getCouponByName/" + couponValue
      )
      .then((res) => res.data);

    setCouponId(couponObj._id);
    setCouponObj(couponObj)

    return couponObj;
  };

  return (
    <FormControl fullWidth required>
      {clientSecret && !paymentStatus && !fullAmountCoupon && (
        <div>
          <div className="bottomSpacing">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <span>{amountLabel()}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordionBox bottomSpacing">
                  {isCouponValid != true && (
                    <TextField
                      id="outlined-basic"
                      label="Ingresa un cupon"
                      variant="outlined"
                      onChange={onCouponNameChange}
                    />
                  )}
                  {isCouponValid && (
                    <TextField
                      disabled
                      id="filled-disabled"
                      label={couponValue}
                      variant="outlined"
                    />
                  )}

                  <Button
                    className="applyButton"
                    variant="contained"
                    onClick={onApplyCoupon}
                    disabled={isCouponValid == true}
                  >
                    Aplicar
                  </Button>
                </div>
                {isCouponValid === false && (
                  <Alert variant="filled" severity="error">
                    Cupon es invalido
                  </Alert>
                )}
                {isCouponValid && (
                  <Button
                    className="removeButton"
                    variant="contained"
                    onClick={onDeleteCoupon}
                  >
                    Eliminar cupon
                  </Button>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              email={values.solicitant.email}
              paymentStatus={paymentStatus}
              setPaymentStatus={setPaymentStatus}
              setPaymentId={setPaymentId}
              fullAmountCoupon={fullAmountCoupon}
            />
          </Elements>
        </div>
      )}
      {paymentStatus && (
        <Alert severity="success">
          Pago existoso, porfavor selecciona siguiente para continuar
        </Alert>
      )}
    </FormControl>
  );
}

export default CheckoutForm;
