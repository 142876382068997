import React from "react";
import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./pages/landing-page/LandingPage";
import { Route, Routes } from "react-router-dom";
import {
  PaletteMode,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import getLPTheme from "./common/getLPTheme";
import Protection from "./pages/protection/Protection";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";

const defaultTheme = createTheme({});

function App() {
  const queryClient = new QueryClient();

  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/amparo" element={<Protection />} />
          </Routes>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
