import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import { Field } from "formik";
import { FormHelperText } from "@mui/material";

function PersonForm({ formik }: any): React.JSX.Element {
  const { values, touched, errors } = formik;
  return (
    <>
      <Grid md={6} xs={12}>
        <FormControl fullWidth required variant="outlined">
          <InputLabel htmlFor="firstName">
            Nombre Completo - Ej: Jose Manuel Perez Gonzalez:
          </InputLabel>
          <Field
            as={OutlinedInput}
            onChange={formik.handleChange}
            value={values.firstName}
            id="firstName"
            label="Nombre Completo - Ej: Jose Manuel Perez Gonzalez:"
            name="firstName"
            error={touched.firstName && Boolean(errors.firstName)}
          />
          <FormHelperText error id="firstName-error">
            {touched.firstName && errors.firstName}
          </FormHelperText>
        </FormControl>
      </Grid>
      {/* <Grid md={6} xs={12}>
        <FormControl fullWidth>
          <InputLabel>Segundo nombre</InputLabel>
          <Field
            as={OutlinedInput}
            onChange={formik.handleChange}
            defaultValue={values.secondName}
            label="Segundo nombre"
            name="secondName"
            error={touched.secondName && Boolean(errors.secondName)}
            helperText={touched.secondName && errors.secondName}
          />
          <FormHelperText error id="firstName-error">
            {touched.secondName && errors.secondName}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Apellido</InputLabel>
          <Field
            as={OutlinedInput}
            onChange={formik.handleChange}
            defaultValue={values.lastName}
            label="Apellido"
            name="lastName"
            error={touched.lastName && Boolean(errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
          <FormHelperText error id="firstName-error">
            {touched.lastName && errors.lastName}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={6} xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Segundo apellido</InputLabel>
          <Field
            as={OutlinedInput}
            onChange={formik.handleChange}
            defaultValue={values.secondLastName}
            label="Segundo apellido"
            name="secondLastName"
            error={touched.secondLastName && Boolean(errors.secondLastName)}
          />
          <FormHelperText error id="firstName-error">
            {touched.secondLastName && errors.secondLastName}
          </FormHelperText>
        </FormControl>
      </Grid> */}
    </>
  );
}

export default PersonForm;
