"use client";

import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import { FormHelperText, Typography } from "@mui/material";
import AddressForm from "./AddressForm";
import { Field } from "formik";
import PersonForm from "./PersonForm";

function DetainedForm({ formik }: any): React.JSX.Element {
  const { values, touched, errors, isValid } = formik;
  return (
    <>
      <Grid container spacing={3}>
        <PersonForm formik={formik} />
      </Grid>
      {/* <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography variant="body1" color="text.secondary">
        Direccion donde se encuentra encerrado el detenido
      </Typography>
      <Grid container mt={1} spacing={3}>
        <AddressForm formik={formik} />
      </Grid> */}
    </>
  );
}

export default DetainedForm;
