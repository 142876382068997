import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Typewriter from "../../../common/components/Typewriter";
import LexiBotImage from "../../../images/LEXIBOT_LANDING_PURPLE_BLACK.png";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
      })}
    >
      <Container
        id="landingSection"
        sx={{
          minHeight: { xs: "500px", md: "700px" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "normal", md: "normal" },
          pt: { xs: 14, sm: 30, md: 35 },
          pb: { xs: 8, sm: 30 },
          pl: 0,
          pr: 0,
        }}
      >
        <Stack direction={{ sm: "column", md: "row" }}>
          <Box
            sx={{
              width: { sm: "100%", md: "550px" },
              height: { xs: "350px", sm: "450px", md: "550px" },
              position: { sm: "normal", md: "absolute" },
              zIndex: -1,
              overflowX: "hidden",
              //filter: { xs: "blur(2px)", sm: "blur(0px)" },
              backgroundImage: `url(${String(LexiBotImage)})`,
              backgroundPosition: { xs: "center center", md: "center top" },
              backgroundSize: {
                xs: "350px 350px",
                sm: "450px 450px",
                md: "550px 550px",
              },
              backgroundRepeat: "no-repeat",
              mt: { sm: -15, md: -23 },
              ml: { sm: 0, md: 65 },
              //mr: 30,
            }}
          />
          <Stack>
            <Stack
              spacing={0}
              useFlexGap
              alignItems={{ xs: "center", md: "normal" }}
              sx={{
                width: { xs: "100%", md: "650px" },
                height: { xs: "210px", md: "240px" },
              }}
            >
              <Typography
                component="h1"
                fontSize={70}
                //minHeight={70}
                sx={{
                  mt: 5,
                  typography: { md: "h1", xs: "h4" },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  // alignSelf: "center",
                  // textAlign: "center",
                }}
              >
                <Typewriter
                  text={"Legalmente avanzada,"}
                  delay={70}
                  infinite={false}
                />
              </Typography>

              <Typography
                component="span"
                fontSize={70}
                //minHeight={70}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  //color: "primary.main",
                  typography: { md: "h1", xs: "h4" },
                }}
              >
                <Typewriter
                  count={19}
                  text={"personalmente tuya."}
                  delay={70}
                  infinite={false}
                />
              </Typography>
              <Typography
                //minHeight={30}
                variant="body1"
                textAlign="center"
                color="primary.light"
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  ml: { sm: 0, md: 24 },
                }}
              >
                <Typewriter
                  count={90}
                  text={"#1 ABOGADA CON IA EN MÉXICO"}
                  delay={30}
                  infinite={false}
                />
              </Typography>
            </Stack>
            <Stack
              spacing={0}
              useFlexGap
              alignItems={{ xs: "center", md: "center" }}
              sx={{ width: { xs: "100%", sm: "100%" } }}
            >
              <Button
                sx={{ width: "150px" }}
                variant="outlined"
                color="primary"
                onClick={() => navigate("amparo")}
              >
                Comenzar
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 400, sm: 700 },
            width: "100%",
            backgroundImage: `url(${String(LexiBotImage)})`,
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        ></Box> */}
      </Container>
    </Box>
  );
}
