import * as React from "react";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormHelperText, Select } from "@mui/material";
import { Field } from "formik";
import moment from "moment";

let stateList = [
  {
    id: 1,
    nombre: "Aguascalientes",
  },
  {
    id: 2,
    nombre: "Baja California",
  },
  {
    id: 3,
    nombre: "Baja California Sur",
  },
  {
    id: 4,
    nombre: "Campeche",
  },
  {
    id: 5,
    nombre: "Chiapas",
  },
  {
    id: 6,
    nombre: "Chihuahua",
  },
  {
    id: 7,
    nombre: "Ciudad de México",
  },
  {
    id: 8,
    nombre: "Coahuila",
  },
  {
    id: 9,
    nombre: "Colima",
  },
  {
    id: 10,
    nombre: "Durango",
  },
  {
    id: 11,
    nombre: "Estado de México",
  },
  {
    id: 12,
    nombre: "Guanajuato",
  },
  {
    id: 13,
    nombre: "Guerrero",
  },
  {
    id: 14,
    nombre: "Hidalgo",
  },
  {
    id: 15,
    nombre: "Jalisco",
  },
  {
    id: 16,
    nombre: "Michoacán",
  },
  {
    id: 17,
    nombre: "Morelos",
  },
  {
    id: 18,
    nombre: "Nayarit",
  },
  {
    id: 19,
    nombre: "Nuevo León",
  },
  {
    id: 20,
    nombre: "Oaxaca",
  },
  {
    id: 21,
    nombre: "Puebla",
  },
  {
    id: 22,
    nombre: "Querétaro",
  },
  {
    id: 23,
    nombre: "Quintana Roo",
  },
  {
    id: 24,
    nombre: "San Luis Potosí",
  },
  {
    id: 25,
    nombre: "Sinaloa",
  },
  {
    id: 26,
    nombre: "Sonora",
  },
  {
    id: 27,
    nombre: "Tabasco",
  },
  {
    id: 28,
    nombre: "Tamaulipas",
  },
  {
    id: 29,
    nombre: "Tlaxcala",
  },
  {
    id: 30,
    nombre: "Veracruz",
  },
  {
    id: 31,
    nombre: "Yucatán",
  },
  {
    id: 32,
    nombre: "Zacatecas",
  },
];

function VehicleForm({ formik }: any): React.JSX.Element {
  const { values, touched, errors, setFieldValue } = formik;
  console.log("licenseState", values.licenseState);

  const SelectList = () => (
    <Select
      onChange={(evt) => setFieldValue("licenseState", evt.target.value)}
      value={values.licenseState}
      displayEmpty
    >
      {stateList.map((e) => (
        <MenuItem value={e.nombre}>{e.nombre}</MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            control={<Checkbox />}
            name="noVehicle"
            onChange={formik.handleChange}
            checked={values.noVehicle}
            label="Omitir vehiculo (Si no recuerda los detalles del vehiculo en el que fue detenido presione omitir.)"
          />
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            required={values.noVehicle ? false : true}
          >
            <InputLabel>Marca y modelo. Ejemplo: Ford Focus:</InputLabel>
            <Field
              as={OutlinedInput}
              id="makeModel"
              onChange={formik.handleChange}
              disabled={values.noVehicle}
              label="Marca y modelo. Ejemplo: Ford Focus:"
              name="makeModel"
              error={touched.makeModel && Boolean(errors.makeModel)}
            />
            <FormHelperText error id="makeModel-error">
              {touched.makeModel && errors.makeModel}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel>Color</InputLabel>
            <Field
              as={OutlinedInput}
              onChange={formik.handleChange}
              disabled={values.noVehicle}
              label="Marca"
              name="color"
            />
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl fullWidth>
            <DatePicker
              onChange={(date: any) => {
                formik.setFieldValue(
                  "year",
                  date ? moment(date).format("YYYY") : null
                );
              }}
              value={values.year ? moment().set("year", values.year) : null}
              disabled={values.noVehicle}
              label="Año"
              views={["year"]}
            />
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel>Placas</InputLabel>
            <Field
              as={OutlinedInput}
              onChange={formik.handleChange}
              disabled={values.noVehicle}
              label="Placas"
              name="licensePlate"
            />
          </FormControl>
        </Grid>
        <Grid md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="licenseState-label">Estado</InputLabel>
            <Select
              labelId="licenseState-label"
              id="licenseState"
              onChange={(evt) =>
                setFieldValue("licenseState", evt.target.value)
              }
              value={values.licenseState}
              label="Estado"
              disabled={values.noVehicle}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stateList.map((e) => (
                <MenuItem key={e.nombre} value={e.nombre}>
                  {e.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default VehicleForm;
