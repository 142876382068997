import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import { Field } from "formik";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import pdf from "../../../files/ContratodeAdhesion.pdf";

const createSummaryLsit = (values: any) => {
  return {
    solicitant: [
      {
        label: "Correo electronico",
        value: values.solicitant.email,
      },
      {
        label: "Nombre Completo",
        value: values.solicitant.firstName,
      },
      // {
      //   label: "Segundo nombre",
      //   value: values.solicitant.secondName,
      // },
      // {
      //   label: "Apellido",
      //   value: values.solicitant.lastName,
      // },
      // {
      //   label: "Segundo apellido",
      //   value: values.solicitant.secondLastName,
      // },
    ],
    detained: [
      {
        label: "Nombre Completo",
        value: values.detained.firstName,
      },
      // {
      //   label: "Segundo nombre",
      //   value: values.detained.secondName,
      // },
      // {
      //   label: "Apellido",
      //   value: values.detained.lastName,
      // },
      // {
      //   label: "Segundo apellido",
      //   value: values.detained.secondLastName,
      // },
    ],
    vehicle: [
      {
        label: "Marca y Modelo",
        value: values.vehicle.makeModel,
      },
      {
        label: "Color",
        value: values.vehicle.color,
      },
      {
        label: "Año",
        value: values.vehicle.year,
      },
      {
        label: "Placas",
        value: values.vehicle.licensePlate,
      },
      {
        label: "Estado",
        value: values.vehicle.licenseState,
      },
    ],
    ocurrence: [
      {
        label: "Detalles sobre el evento",
        value: values.ocurrence.detailedExplanation,
      },
      {
        label: "Hora de evento",
        value: values.ocurrence.ocurrenceDate,
      },
      {
        label: "Tiempo de evento",
        value: values.ocurrence.ocurrenceDate,
      },
    ],
  };
};

const renderList = (list: any[]) => {
  return list.map((item) => (
    <Grid xs={12} md={item.label === "Detalles sobre el evento" ? 12 : 6}>
      <Stack
        sx={{
          direction: {
            xs: "column",
            md: item.label === "Detalles sobre el evento" ? "column" : "row",
          },
        }}
        spacing={1}
        mb={1.5}
      >
        <Typography
          variant="body1"
          color="text.secondary"
          style={{
            fontWeight: "bold",
            width: "fit-content",
            marginBottom: "-5px",
          }}
        >
          {item.label}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {item.value}
        </Typography>
      </Stack>
    </Grid>
  ));
};

function SummaryForm({
  values,
  accepted,
  setAccepted,
}: any): React.JSX.Element {
  const summaryList = createSummaryLsit(values);
  return (
    <>
      <Typography
        variant="h6"
        color="text.primary"
        style={{ marginBottom: "10px" }}
      >
        Solicitante
      </Typography>
      <Grid container>{renderList(summaryList.solicitant)}</Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Typography
        variant="h6"
        color="text.primary"
        style={{ marginBottom: "10px" }}
      >
        Detenido
      </Typography>
      <Grid container>{renderList(summaryList.detained)}</Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      {!values.vehicle.noVehicle && (
        <>
          <Typography
            variant="h6"
            color="text.primary"
            style={{ marginBottom: "10px" }}
          >
            Vehiculo
          </Typography>
          <Grid container>{renderList(summaryList.vehicle)}</Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
      <Typography
        variant="h6"
        color="text.secondary"
        style={{ marginBottom: "10px" }}
      >
        Evento
      </Typography>
      <Grid container>{renderList(summaryList.ocurrence)}</Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <a
        onClick={() => window.open(pdf, "_blank")}
        style={{ textDecoration: "underline", cursor: "pointer" }}
        //href={pdf}
        // target="_blank"
        download="LexibotTerminos.pdf"
      >
        Terminos y Condiciones
      </a>
      <Grid xs={12}>
        <FormControlLabel
          control={<Checkbox />}
          name="termsConditions"
          checked={accepted}
          onChange={(_, val: any) => setAccepted(val)}
          label="Aceptar Terminos y Condiciones"
        />
      </Grid>
    </>
  );
}

export default SummaryForm;
