import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import ParkIcon from "@mui/icons-material/Park";

const items = [
  {
    icon: <FlashOnIcon />,
    title: "RÁPIDO",
    description:
      "Obtén resultados legales en una fracción del tiempo habitual, maximizando la eficiencia sin comprometer la calidad.",
  },
  {
    icon: <CheckCircleIcon />,
    title: "EFECTIVO",
    description:
      "Mejora la resolución de tus asuntos legales con un sistema que combina inteligencia artificial y expertise legal.",
  },
  {
    icon: <PhoneAndroidIcon />,
    title: "DIGITAL",
    description:
      "Accede a servicios legales desde cualquier lugar, en cualquier momento, a través de una plataforma completamente digital.",
  },
  {
    icon: <PersonPinIcon />,
    title: "SIEMPRE CONTIGO",
    description:
      "Tu apoyo legal constante, preparada para enfrentar cualquier desafío y simplificar tu vida.",
  },
  {
    icon: <EmojiEmotionsIcon />,
    title: "PREFERENCIA DIGITAL",
    description:
      "No va a querer regresar a la asesoría legal tradicional de siempre, eligiendo la rapidez y simplicidad digital.",
  },
  {
    icon: <ParkIcon />,
    title: "COMPROMISO ECOLÓGICO",
    description:
      "Nuestro compromiso con el planeta es firme: eliminamos el papel de nuestros procesos, abrazando un futuro legal sostenible.",
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Ventajas
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Explora lo que nos distingue: soluciones legales rápidas, precisas y
            totalmente digitales, transformando tu experiencia legal de manera
            integral.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                  alignItems: "center",
                }}
              >
                <Box sx={{ opacity: "50%" }}>
                  <Stack
                    width={"100%"}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                  >
                    {item.icon}
                  </Stack>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
