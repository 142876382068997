import { useEffect, useRef, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import EventIcon from "@mui/icons-material/Event";
import { SummarizeOutlined } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import CarIcon from "@mui/icons-material/DirectionsCarRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Button, Grid, LinearProgress } from "@mui/material";
import getLPTheme from "../../common/getLPTheme";
import SolicitantForm from "./forms/Solicitant";
import FormParent from "./FormParent";
import DetainedForm from "./forms/Detained";
import OcurrenceForm from "./forms/Ocurrence";
import VehicleForm from "./forms/Vehicle";
//import OfficersForm from "./forms/Officers";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Wizard } from "../../common/components";
import { usePostProtection } from "../../hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../landing-page/components/CheckoutForm";
import SummaryForm from "./forms/SummaryForm";
import SuccessErrorComponent from "./SuccessErrorComponent";
//import { PdfDocument } from "@ironsoftware/ironpdf";
//import puppeteer from "puppeteer";

const steps: any = [
  { id: "solicitant", label: "Solicitante", icon: PersonIcon },
  { id: "detained", label: "Detenido", icon: GroupIcon },
  { id: "vehicle", label: "Vehiculo", icon: CarIcon },
  { id: "ocurrence", label: "Sucedido", icon: EventIcon },
  { id: "summary", label: "Resumen", icon: SummarizeOutlined },
  { id: "payment", label: "Pago", icon: PaymentIcon },
];

const formsInfo = (
  solicitantFormik: any,
  detainedFormik: any,
  ocurrenceFormik: any,
  vehicleFormik: any,
  paymentFormik: any
) => {
  const [
    solicitantValues,
    detainedValues,
    ocurrenceValues,
    vehicleValues,
    paymentValues,
  ] = [
    solicitantFormik.values,
    detainedFormik.values,
    ocurrenceFormik.values,
    vehicleFormik.values,
    paymentFormik.values,
  ];
  return {
    solicitant: {
      Form: SolicitantForm,
      title: "Solicitante",
      subheader:
        "Datos de la persona que solicita el amparo y NO de la persona detenida.",
      formik: solicitantFormik,
    },
    detained: {
      Form: DetainedForm,
      title: "Detenido",
      subheader: "Datos sobre el detenido",
      formik: detainedFormik,
    },
    vehicle: {
      Form: VehicleForm,
      title: "Vehiculo",
      subheader: "Datos sobre el vehiculo en el cual fue detenido",
      formik: vehicleFormik,
    },
    ocurrence: {
      Form: OcurrenceForm,
      title: "Hechos",
      subheader: "Datos sobre los detalles de los hechos",
      formik: ocurrenceFormik,
    },
    summary: {
      Form: SummaryForm,
      title: "Resumen",
      subheader: "Resumen de Informacion",
      values: {
        detained: detainedValues,
        vehicle: vehicleValues,
        ocurrence: ocurrenceValues,
        solicitant: solicitantValues,
      },
    },
    payment: {
      Form: CheckoutForm,
      title: "Pago",
      subheader: "Proceso de pago",
      formik: paymentFormik,
      values: {
        solicitant: solicitantValues
      }
    },
  };
};

const person = {
  obj: {
    firstName: null,
    secondName: null,
    lastName: null,
    secondLastName: null,
  },
  yup: {
    firstName: Yup.string().required("Ingrese su primer nombre"),
    // secondName: Yup.string().notRequired(),
    // lastName: Yup.string().required("Ingrese su primer apellido"),
    // secondLastName: Yup.string().required("Ingrese su segundo apellido"),
  },
};

const address = {
  obj: { street: null, postalCode: null, city: null, state: "Jalisco" },
  yup: {
    street: Yup.string().required("Ingrese la direccion"),
    postalCode: Yup.string().required("Ingrese el codigo postal"),
    city: Yup.string().required("Ingrese la ciudad"),
    state: Yup.string().required("Ingrese el estado"),
  },
};

const vehicle = {
  obj: {
    makeModel: null,
    year: null,
    color: null,
    licensePlate: null,
    licenseState: null,
    noVehicle: false,
  },
  yup: {
    noVehicle: Yup.boolean(),
    makeModel: Yup.string()
      .when("noVehicle", {
        is: false,
        then(schema) {
          return schema.required("Ingrese la marca y el modelo del vahiculo");
        },
      })
      .when("noVehicle", {
        is: true,
        then(schema) {
          return schema.notRequired();
        },
      }),
  },
};

const payment = {
  obj: {
    paymentId: "",
    paymentStatus: false,
    couponId: "",
    fullAmountCoupon: false,
  },
  yup: {
    paymentStatus: Yup.boolean().oneOf(
      [true],
      "The terms and conditions must be accepted."
    ),
  },
};

const formikInitValues = {
  solicitant: {
    initialValues: {
      ...person.obj,
      email: null,
    },
    validationSchema: Yup.object({
      ...person.yup,
      email: Yup.string()
        .required("Ingrese su correo")
        .email("Ingrese un correo electronico valido"),
    }),
    validateOnMount: true,
    onSubmit: (values: any) => {
      console.log(values);
    },
  },
  detained: {
    initialValues: {
      ...person.obj,
      ...address.obj,
    },
    validationSchema: Yup.object({ ...person.yup }),
    validateOnMount: true,
    onSubmit: (values: any) => {
      console.log(values);
    },
  },
  vehicle: {
    initialValues: { ...vehicle.obj },
    validationSchema: Yup.object(vehicle.yup),
    validateOnMount: true,
    onSubmit: (values: any) => {
      console.log(values);
    },
  },
  ocurrence: {
    initialValues: {
      ...person.obj,
      ...address.obj,
      detailedExplanation: null,
      ocurrenceDate: null,
      ocurrenceTime: null,
    },
    validationSchema: Yup.object({
      detailedExplanation: Yup.string()
        .required("Ingrese un relato breve sobre los hechos")
        .min(60, "Por favor escriba un poco mas sobre el evento"),
      ocurrenceDate: Yup.string().required("Ingrese la fecha de los hechos"),
      ocurrenceTime: Yup.string().required(
        "Ingrese el tiempo en el que ocurrieron los hechos"
      ),
    }),
    validateOnMount: true,
    onSubmit: (values: any) => {
      console.log(values);
    },
  },
  payment: {
    initialValues: { ...payment.obj },
    validationSchema: Yup.object(payment.yup),
    validateOnMount: true,
    onSubmit: (values: any) => {
      console.log(values);
    },
  },
};

export default function CustomizedSteppers() {
  const [isMobile, setIsMobile] = useState(false);
  const [termsConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const navigate = useNavigate();
  const paymentFormik = useFormik(formikInitValues.payment);
  const solicitantFormik = useFormik(formikInitValues.solicitant);
  const detainedFormik = useFormik(formikInitValues.detained);
  const ocurrenceFormik = useFormik(formikInitValues.ocurrence);
  const vehicleFormik = useFormik(formikInitValues.vehicle);
  const cacheProtection = useRef(
    localStorage.getItem("protectionValues") || null
  );

  const saveProtection = usePostProtection();

  const [step, setStep] = useState(0);
  const forms: any = formsInfo(
    solicitantFormik,
    detainedFormik,
    ocurrenceFormik,
    vehicleFormik,
    paymentFormik
  );
  const formInfo: any = forms[steps[step].id];
  const { formik: currentFormik } = formInfo;

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  const handleSaveProtectionCache = () => {
    const json = {
      solicitant: solicitantFormik.values,
      detained: detainedFormik.values,
      ocurrence: ocurrenceFormik.values,
      vehicle: vehicleFormik.values,
      payment: paymentFormik.values,
    };
    localStorage.setItem("protectionValues", JSON.stringify(json));
  };

  const setFormikValuesFromCache = () => {
    const item = cacheProtection.current;
    const { solicitant, detained, ocurrence, vehicle, payment }: any = item
      ? JSON.parse(item)
      : {};
    solicitantFormik.setValues(solicitant);
    detainedFormik.setValues(detained);
    ocurrenceFormik.setValues(ocurrence);
    vehicleFormik.setValues(vehicle);
    paymentFormik.setValues(payment);
  };

  const createProtectionObj = () => {
    return {
      solicitant: solicitantFormik.values,
      detainedPerson: {
        ...detainedFormik.values,
        currentAddress: detainedFormik.values,
      },
      detailedExplanation: ocurrenceFormik.values.detailedExplanation,
      ocurrenceDate: `${ocurrenceFormik.values.ocurrenceDate}T${moment(
        ocurrenceFormik.values.ocurrenceTime
      ).format("hh:mm:ss")}`,
      vehicle: vehicleFormik.values.noVehicle ? null : vehicleFormik.values,
      payment: {
        paymentId: paymentFormik.values.paymentId,
        couponId: paymentFormik.values.couponId,
        fullAmountCoupon: paymentFormik.values.fullAmountCoupon
      },
    };
  };

  const handleSubmit = () => {
    const protectionObj = createProtectionObj();
    handleSaveProtectionCache();
    saveProtection.mutate(protectionObj);
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const navigateHome = async () => {
    await delay(10000);
    navigate("/");
  };

  if (saveProtection.isSuccess || saveProtection.isError)
    return <SuccessErrorComponent isSuccess={saveProtection.isSuccess} />;

  return (
    <ThemeProvider theme={createTheme(getLPTheme("light"))}>
      {saveProtection.isPending && (
        <Stack
          sx={{
            width: "50%",
            color: "grey.500",
            position: "fixed",
            top: "50%",
            left: "50%",
            WebkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
          }}
          spacing={2}
        >
          <LinearProgress
            sx={{
              height: "10px",
            }}
            color="secondary"
          />
        </Stack>
      )}
      {!saveProtection.isPending && !saveProtection.isSuccess && (
        <Stack
          sx={{
            width: "100%",
            alignContent: "center",
            mt: 3,
          }}
          spacing={4}
        >
          <Grid justifyContent="center" container spacing={0}>
            <Grid item xs={2}>
              <Stack
                sx={{
                  alignItems: "end",
                }}
              >
                <Button
                  onClick={() => navigate("/")}
                  startIcon={<ArrowBackIcon />}
                >
                  {isMobile ? "" : "REGRESAR"}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack
                sx={{
                  width: "100%",
                  alignContent: "center",
                  mt: 1,
                }}
              >
                <Wizard step={step} steps={steps} />
              </Stack>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>

          <Grid justifyContent="center" container spacing={0}>
            <Grid sx={{ alignSelf: "center" }} lg={8} md={8} xs={12}>
              <FormParent
                hasCache={!!cacheProtection.current}
                fillDataCallBack={setFormikValuesFromCache}
                subheader={formInfo.subheader}
                title={formInfo.title}
                prevShowing={step !== 0}
                handleNext={() =>
                  step !== steps.length - 1 ? setStep(step + 1) : handleSubmit()
                }
                handlePrev={() => (step !== 0 ? setStep(step - 1) : undefined)}
                nextDisabled={
                  currentFormik
                    ? !currentFormik.isValid
                    : !termsConditionsAccepted
                }
              >
                {formInfo.title === "Resumen" ? (
                  <formInfo.Form
                    values={formInfo.values}
                    accepted={termsConditionsAccepted}
                    setAccepted={setTermsAndConditionsAccepted}
                  />
                ) : (
                  <FormikProvider value={formInfo.formik}>
                    <formInfo.Form formik={formInfo.formik} values = {formInfo.values}/>
                  </FormikProvider>
                )}
              </FormParent>
            </Grid>
          </Grid>
        </Stack>
      )}
    </ThemeProvider>
  );
}
