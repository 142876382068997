import React from "react";
import { Button, Grow, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

const SubmissionMessage = ({ isSuccess }: any) => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grow in={true} timeout={2000}>
        {isSuccess ? (
          <CheckCircleIcon
            sx={{ fontSize: { xs: 130, md: 200 } }}
            color="primary"
          />
        ) : (
          <ErrorIcon
            sx={{ fontSize: { xs: 130, md: 200 }, color: "orange" }}
            color="primary"
          />
        )}
      </Grow>
      <Grow
        in={true}
        timeout={2000}
        style={{ maxWidth: 650, marginLeft: 10, marginRight: 10 }}
      >
        {isSuccess ? (
          <div style={{ marginBottom: 40 }}>
            <Typography style={{ marginTop: 16, fontSize: 16 }}>
              ¡Felicidades! Tu amparo está en proceso y en unos momentos
              recibirás un correo electrónico con más detalles. Revisa tanto tu
              bandeja de entrada como tu carpeta de spam.
              <br />
              <br />
            </Typography>
            <Stack direction="row" spacing={1}>
              <ErrorIcon
                sx={{
                  alignSelf: "center",
                  fontSize: { xs: 18, marginTop: "5px" },
                  color: "#5469d4",
                }}
                color="primary"
              />
              <div style={{ fontSize: 13, fontStyle: "italic" }}>
                Si no recibes el correo, no dudes en contactarme en:
                soporte@lexibot.com.mx.
              </div>
            </Stack>
          </div>
        ) : (
          <Typography
            align="center"
            style={{ marginTop: 16, marginBottom: 40, fontSize: 16 }}
          >
            La creacion del amparo ha tenido un error.
            <br /> El cobro no se realizo debido al error. Por favor intente de
            nuevo, su amparo fue guardado y puede usar la opcion de "Crear
            amparo con la informacion anterior" para no llenar la forma de nuevo
            e ir directamente al cobro.
          </Typography>
        )}
      </Grow>
      <Grow in={true} timeout={2000}>
        <Button
          sx={{ minWidth: 260, maxWidth: 300 }}
          onClick={() => navigate("/")}
          variant="outlined"
        >
          {"ENTENDIDO, REGRESAR A INICIO"}
        </Button>
      </Grow>
    </Box>
  );
};

export default SubmissionMessage;
