"use client";

import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Grid from "@mui/material/Unstable_Grid2";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { FormHelperText, Input, Typography } from "@mui/material";
import { Field } from "formik";
import moment from "moment";
import { MobileTimePicker } from "@mui/x-date-pickers";

function OcurrenceForm({ formik }: any): React.JSX.Element {
  const { values, touched, errors } = formik;

  console.log(touched.ocurrenceDate);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
          Por favor escriba a detalle como y donde sucedieron los hechos.
          Incluya detalles sobre: Calle en la que fue detenido y un relato breve
          sobre lo que ocurrio.
          <br /> <br />
          <Typography variant="body1" color="text.secondary">
            Ejemplos:
          </Typography>
          - Manejaba en mi mercedes c200 2022 por avenida patria con cruce con
          avenida vallarta en el municipio de Zapopan, cuando fui detenido por
          un retén de alcoholemia y al negarme a hacerme la prueba de alcohol ya
          que no había un médico presente me llevaron arrestado.
          <br /> <br />
          - Iba circulando en mi carro jetta gli 2019 en la avenida acueducto
          con sentido a periférico en el municipio de Zapopan, cuando me detuvo
          un retén de alcoholímetro por supuestos elementos de transito, me
          hicieron la prueba y salí positivo cuando nunca tome nada de alcohol,
          les pedí que me la volvieran a hacer porque pudo haber fallado su
          aparato o quizás ya se había utilizado con alguien más pero los
          oficiales ya no quisieron, bajándome de mi vehículo, esposándome y
          llevándome detenido a la curva.
          <br /> <br />
        </Typography>
        <FormControl fullWidth required>
          <InputLabel>Detalles sobre el evento</InputLabel>
          <Field
            as={OutlinedInput}
            onChange={formik.handleChange}
            defaultValue={values.detailedExplanation}
            multiline
            label="Detalles sobre el evento"
            name="detailedExplanation"
            error={
              touched.detailedExplanation && Boolean(errors.detailedExplanation)
            }
          />
          <FormHelperText error id="detailedExplanation-error">
            {touched.detailedExplanation && errors.detailedExplanation}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={4} xs={7}>
        <FormControl fullWidth required>
          <MobileDatePicker
            name="ocurrenceDate"
            onChange={(date: any) =>
              formik.setFieldValue(
                "ocurrenceDate",
                moment(date).format("YYYY-MM-DD")
              )
            }
            value={values.ocurrenceDate ? moment(values.ocurrenceDate) : null}
            slotProps={{
              textField: {
                error: touched.ocurrenceDate && Boolean(errors.ocurrenceDate),
                onClickCapture: () =>
                  formik.setFieldTouched("ocurrenceDate", true),
              },
            }}
            label="Fecha de evento"
          />
          <FormHelperText error id="ocurrenceDate-error">
            {touched.ocurrenceDate && errors.ocurrenceDate}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid md={4} xs={7}>
        <FormControl fullWidth required>
          <MobileTimePicker
            name="ocurrenceTime"
            onChange={(date: any) =>
              formik.setFieldValue(
                "ocurrenceTime",
                moment(date) //.format("hh:mm:ss")
              )
            }
            value={values.ocurrenceTime ? moment(values.ocurrenceTime) : null}
            slotProps={{
              textField: {
                error: touched.ocurrenceTime && Boolean(errors.ocurrenceTime),
                onClickCapture: () =>
                  formik.setFieldTouched("ocurrenceTime", true),
              },
            }}
            label="Tiempo de evento"
          />
          <FormHelperText error id="ocurrenceTime-error">
            {touched.ocurrenceTime && errors.ocurrenceTime}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default OcurrenceForm;
