import { useState, useEffect } from "react";

const Typewriter = ({ text, delay, infinite, count = 0 }: any) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [countStart, setCountStart] = useState(count);

  useEffect(() => {
    let timeout: any;

    if (countStart !== 0) {
      timeout = setTimeout(() => {
        setCountStart(countStart - 1);
      }, delay);
    } else if (currentIndex < text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      // ADD THIS CHECK
      setCurrentIndex(0);
      setCurrentText("");
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, countStart, delay, infinite, text]);

  return <span>{currentText}</span>;
};

export default Typewriter;
