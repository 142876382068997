"use client";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import PersonForm from "./PersonForm";
import { Formik, Form, Field, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";

function SolicitantForm({ formik }: any): React.JSX.Element {
  const { values, touched, errors, isValid } = formik;

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Correo electronico</InputLabel>
          <Field
            onChange={formik.handleChange}
            as={OutlinedInput}
            value={values.email}
            label="Correo electronico"
            name="email"
            error={touched.email && Boolean(errors.email)}
          />
          <FormHelperText error id="firstName-error">
            {touched.email && errors.email}
          </FormHelperText>
        </FormControl>
      </Grid>
      <PersonForm formik={formik} />
    </Grid>
  );
}

export default SolicitantForm;
