import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


interface paymentFormProps {
    email: any;
    paymentStatus: boolean;
    setPaymentStatus: any;
    setPaymentId: any;
    fullAmountCoupon: boolean;
}

export default function PaymentForm(props : paymentFormProps) {

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if(!props.fullAmountCoupon) {

      if (!stripe) {
        return;
      }

      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          if(paymentIntent?.status !== undefined){

              switch (paymentIntent.status) {
                  case "succeeded":
                  setMessage("Payment succeeded!");
                  break;
                  case "processing":
                  setMessage("Your payment is processing.");
                  break;
                  case "requires_payment_method":
                  setMessage("Your payment was not successful, please try again.");
                  break;
                  default:
                  setMessage("Something went wrong.");
                  break;
              }
          }
      });
    }
  }, [stripe]);

  const handleSubmit = async (e : any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
        receipt_email: props.email
      },
        redirect: 'if_required' 
    }).then(function(result) {
        props.setPaymentId(result.paymentIntent?.id)
        // console.log(result);
        if (result.error) {
          console.log("Payment was declined")
          setMessage("El pago ha fallado, porfavor intenta otra vez")
          setIsLoading(false);
          props.setPaymentStatus(false);
          // Inform the customer that there was an error.
        }
        else{
          props.setPaymentStatus(true);
        }

      });;

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error == undefined || error.type === "card_error" || error.type === "validation_error") {
    //     if(error.message !== undefined){
    //         setMessage(error.message);
    //     }
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    await stripe.retrievePaymentIntent
  };

  const paymentElementOptions : any = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!props.fullAmountCoupon && (<div>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pagar"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message" style={{
          color: 'red',
          fontSize: '16px',  // Adjust size as needed
          fontWeight: 'bold', // Optional: make the text bold
          }}>{message}</div>}
      </div>)}
    </form>
  );
}