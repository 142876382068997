import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const accordionList = [
  {
    question: "¿Qué es Lexibot?",
    answer:
      "Soy la primera abogada virtual con inteligencia artificial en México, especializada en facilitar el acceso a servicios legales rápidos y efectivos.",
  },
  {
    question: "¿Qué información necesito para iniciar un amparo con Lexibot?",
    answer:
      "Para iniciar un proceso de amparo conmigo, necesitas proporcionar tu nombre, correo electrónico, nombre del detenido, los detalles del incidente y datos del vehículo, si es posible.",
  },
  {
    question: "¿Puedo solicitar el servicio de Lexibot para alguien más?",
    answer:
      "Sí, puedes solicitar un amparo para un amigo o familiar siempre que tengas la información necesaria y su consentimiento.",
  },
  {
    question: "¿Cuánto tiempo tarda el proceso con Lexibot?",
    answer:
      "Una vez que completes tu solicitud y realices el pago, recibirás una confirmación inmediata de tu pago. Luego, dentro de 2 a 4 horas, te enviaré el documento de suspensión provisional por correo electrónico.*Aplicando términos y condiciones*",
  },
  {
    question: "¿Es seguro usar Lexibot?",
    answer:
      "Como tu abogada virtual, garantizo la máxima seguridad y privacidad. Utilizo tecnología de cifrado avanzada para proteger todos tus datos personales y aseguro que toda la información se maneje con la mayor confidencialidad.",
  },
  {
    question: "¿En qué ciudades está disponible Lexibot?",
    answer:
      "Actualmente, solo estoy disponible en la ZMG de Guadalajara. Estoy trabajando para expandir mi cobertura a más ciudades en el futuro cercano.",
  },
  {
    question: "¿Lexibot también maneja otros tipos de casos legales?",
    answer:
      "En este momento, estoy especializada en gestionar amparos para detenciones en retenes de alcoholemia. Sin embargo, estoy diseñada para expandir mis capacidades y planeo ofrecer servicios adicionales en diferentes áreas legales en el futuro.",
  },
  {
    question:
      "¿Puedo utilizar Lexibot si soy extranjero y me detienen en un retén de alcoholemia?",
    answer:
      "Claro, mi servicio está disponible para cualquier persona, independientemente de su nacionalidad, siempre que la detención ocurra dentro de las ciudades donde opero.",
  },
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Preguntas frecuentes
      </Typography>
      <Box sx={{ width: "100%" }}>
        {accordionList.map((item) => (
          <Accordion
            expanded={expanded === item.question}
            onChange={handleChange(item.question)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography component="h3" variant="subtitle2">
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}

// <Accordion
//           expanded={expanded === "panel1"}
//           onChange={handleChange("panel1")}
//         >
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel1d-content"
//             id="panel1d-header"
//           >
//             <Typography component="h3" variant="subtitle2">
//               How do I contact customer support if I have a question or issue?
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography
//               variant="body2"
//               gutterBottom
//               sx={{ maxWidth: { sm: "100%", md: "70%" } }}
//             >
//               You can reach our customer support team by emailing
//               <Link> support@email.com </Link>
//               or calling our toll-free number. We&apos;re here to assist you
//               promptly.
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
